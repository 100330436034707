body {
  background-color: #FAFAFA;
}

.App-form {
  background-color: #FFFFFF;
}

.App-header {
  background-color: #FFFFFF;
  /* height: 150px; */
  padding: 20px;
  /* color: white; */
  position: relative;
  text-align: center;
}

.App-header > .login {
  position: absolute;
  right: 0;
  top: 15px;
}

.App-header img {
  height: 100px;
  /* width: 200px; */
}

.App-title {
  font-size: 1.5em;
}

.App-heading {
  font-size: 1.25em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

h3 {
  padding-top: 30px;
}

/* .text-danger { color: red; } */
